
export enum Environment {
    Production = 0,
    Qa = 1,
    Dev = 2,
}

const ifEmptyDefault = (setting: string, defaultValue: string) => setting === "" || setting.startsWith("__INJECT_") ? defaultValue : setting

export const TENANT_ID = ifEmptyDefault("f341cebc-582c-4a33-8c71-139529a19ffb", process.env.REACT_APP_BIO_APP_AAD_TENANT_ID!)
export const APIAPP_ID = ifEmptyDefault("79a59aa6-101e-4774-bba1-0b8fde6851da", process.env.REACT_APP_BIO_APP_AAD_APIAPP_ID!)
export const WEBAPP_ID = ifEmptyDefault("d2a37e72-5276-4153-87a4-8567e8484774", process.env.REACT_APP_BIO_APP_AAD_WEBAPP_ID!)
export const B2C_AUTHORITY = ifEmptyDefault("techyoticb2c", process.env.REACT_APP_BIO_APP_B2C_AUTHORITY!)
export const B2C_TENANT_ID = ifEmptyDefault("5cce7bfb-d524-45ff-b186-29961857dd4b", process.env.REACT_APP_BIO_APP_B2C_TENANT_ID!)
export const B2C_APIAPP_ID = ifEmptyDefault("dda00f12-917f-4ece-9cd6-f64f320fef6f", process.env.REACT_APP_BIO_APP_B2C_APIAPP_ID!)
export const B2C_WEBAPP_ID = ifEmptyDefault("4bdb936c-4d0e-4bef-bb71-170f9be899c2", process.env.REACT_APP_BIO_APP_B2C_WEBAPP_ID!)
export const API_URL = ifEmptyDefault("https://bioapp.qa.techyotic.com", process.env.REACT_APP_BIO_APP_FALLBACK_API_URL!)
export const VERSION = process.env.REACT_APP_BIO_APP_VERSION ?? "0.0.0"
export const COMMIT = process.env.REACT_APP_BIO_APP_COMMIT ?? "0000000"
export const BUILD_DATE = process.env.REACT_APP_BIO_APP_BUILD_DATE ?? "00000000"
const ENVIRONMENT_RAW = ifEmptyDefault("Qa", process.env.REACT_APP_BIO_APP_ENVIRONMENT || "Production")

export const ENVIRONMENT = ENVIRONMENT_RAW === "Development" ? Environment.Dev : ENVIRONMENT_RAW === "Qa" ? Environment.Qa : Environment.Production